import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingScreen from "react-loading-screen";
import axios from "axios";
import loader from "../../Resources/loader.gif";
import { useState } from "react";
import qs from "query-string";
import { useToken } from "../../auth/useToken";
import Otpform from "./otpform";

export default function Login() {
  const navigate = useNavigate();

  const [loading, setLoadig] = useState(false);
  const [token, setToken] = useToken();
  const [show, setShow] = useState(true);
  const [num, setNum] = useState();
  const [dbnum, setDbNum] = useState();

  const notifysuccess = (msg) => toast.success(msg);
  const notifyerror = (msg) => toast.error(msg);

  const login = async (e) => {
    e.preventDefault();
    setLoadig(true);

    var data = {
      username: e.target.uname.value,
      password: e.target.password.value,
    };

    try {
      await axios
        .post(
          process.env.REACT_APP_API_URL + "/auth/login",
          qs.stringify(data)
        )
        .then((response) => {
          setLoadig(false);
          if (response.data.status === 0) {
            let num = "94" + response.data.phone.substring(1);
            setNum(num);
            setDbNum(response.data.phone);
            setShow(false);
          } else {
            setToken(response.data.token);
            notifysuccess("Login successful!");
            navigate("/feed");
          }
        });
    } catch (error) {
      setLoadig(false);
      if (typeof error.response.data.message != "undefined") {
        notifyerror(error.response.data.message);
      } else {
        notifyerror("Login Failed!");
      }
    }
  };

  return (
    <LoadingScreen loading={loading} bgColor="#ffffffcc" logoSrc={loader}>
      <body>
        {/* <!-- Global Container --> */}

        <div className="flex items-center justify-center min-h-screen">
          <div className="z-0 absolute left-[200px] bottom-[-150px] ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="496"
              height="496"
              viewBox="0 0 496 496"
            >
              <g
                id="Ellipse_20"
                data-name="Ellipse 20"
                fill="none"
                stroke="#00008b"
                stroke-width="71"
              >
                <circle cx="248" cy="248" r="248" stroke="none" />
                <circle cx="248" cy="248" r="212.5" fill="none" />
              </g>
            </svg>
          </div>

          <div className="z-0 absolute right-[200px] top-[-150px] ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="496"
              height="496"
              viewBox="0 0 496 496"
            >
              <g
                id="Ellipse_20"
                data-name="Ellipse 20"
                fill="none"
                stroke="#00008b"
                stroke-width="71"
              >
                <circle cx="248" cy="248" r="248" stroke="none" />
                <circle cx="248" cy="248" r="212.5" fill="none" />
              </g>
            </svg>
          </div>
          {/* <!-- Card--> */}

          <div className="z-10 relative flex flex-col-reverse flex-col m-6 space-y-10 bg-white shadow-2xl rounded-[60px] md:flex-row md:space-y-0 md:m-0 ">
            {/* <!--Lef Side--> */}

            <div className="md:mt-[275px] md:w-[550px] mt-[50px] w-[400px]">
              <div className="text-left md:pl-[100px] w-[400px] pl-[30px] pb-[30px]">
                {show ? (
                  <div>
                    <h2 className=" font-bold text-3xl tracking-[0.03em] text-[#00008b] font-bold">
                      Login
                    </h2>
                    <p className="text-slate-500 pt-2 text-left">
                      Don't have an account?{" "}
                      <Link
                        to="signup"
                        className="text-slate-800 underline font-bold"
                      >
                        {" "}
                        Sign up
                      </Link>
                    </p>
                    <div className="text-left pt-4">
                      <form onSubmit={login}>
                        <input
                          type="text"
                          required
                          placeholder="Your phone number"
                          name="uname"
                          className="placeholder:font-thin font-bold bg-[#f5f6f8] caret-[#00008b] p-2 placeholder-gray-400 focus:shadow-md outline-none w-[350px] "
                        />
                        <input
                          type="password"
                          name="password"
                          required
                          placeholder="Your password"
                          className="mt-3 placeholder:font-thin font-bold  p-2 placeholder-gray-400 bg-[#f5f6f8] focus:shadow-md outline-none w-[350px] "
                        />
                        <button
                          type="submit"
                          className="text-center p-3 rounded-xl mt-3 w-[350px] text-white font-thin text-sm bg-[#00008b] hover:bg-[#050544] duration-300"
                        >
                          Login
                        </button>
                      </form>
                    </div>
                  </div>
                ) : (
                  <Otpform number={num} dbnumber={dbnum}/>
                )}
              </div>
            </div>

            {/* <!-- Right Side--> */}

            <div className="bg-[#00008b] md:h-[700px] md:w-[550px]  md:rounded-r-[60px]  rounded-t-[60px] ">
              <h1 className="text-white text-center font-bold text-4xl md:mt-[275px] mt-[50px] ">
                Welcome back
              </h1>
              <img
                src="/images/logomain.svg"
                alt="fleeinn"
                className="mt-3 mx-auto w-[300px] mb-[50px]"
              />
            </div>

            <div className="absolute md:right-[-100px] md:top-[400px] md:block hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="200"
                height="200"
                viewBox="0 0 337 337"
              >
                <g
                  id="Ellipse_21"
                  data-name="Ellipse 21"
                  fill="none"
                  stroke="#fff"
                  stroke-width="81"
                >
                  <circle cx="168.5" cy="168.5" r="168.5" stroke="none" />
                  <circle cx="168.5" cy="168.5" r="128" fill="none" />
                </g>
              </svg>
            </div>

            <div className="absolute right-[405px] top-[-20px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="200"
                height="175"
                viewBox="0 0 337 337"
              >
                <g
                  id="Ellipse_21"
                  data-name="Ellipse 21"
                  fill="none"
                  stroke="#fff"
                  stroke-width="81"
                >
                  <circle cx="168.5" cy="168.5" r="168.5" stroke="none" />
                  <circle cx="168.5" cy="168.5" r="128" fill="none" />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </body>
    </LoadingScreen>
  );
}
