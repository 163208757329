import { useNavigate, useParams } from "react-router-dom";
import PropertyNav from "./nav";
import { useToken } from "../../../auth/useToken";
import { useState, useEffect } from "react";
import LoadingScreen from "react-loading-screen";
import loader from "../../../Resources/loader.gif";
import axios from "axios";
import { toast } from "react-toastify";

export default function PropertyAmenities() {

  let navigate = useNavigate();

  let { id } = useParams(); 

  const [token, setToken] = useToken();
  const [loading, setLoadig] = useState(false);
  const [amenities, setAmenities] = useState();
  const [safeamenities, setSafeamenities] = useState();
  const [selected, setSelected] = useState([]);

  const notifyerror = (msg) => toast.error(msg);

  const getAmenities = (id) => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/amenity/all/" + id,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        setLoadig(false);
        const data = response.data.amenities;
        if (data == "") {
          notifyerror("Feed error!");
        } else {
          setAmenities(data);
        }
      })
      .catch(function (error) {
        setLoadig(false);
        console.log(error);
      });
  }; 
  
  const getSafeAmenities = (id) => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/amenity/all/" + id,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        setLoadig(false);
        const data = response.data.amenities;
        if (data == "") {
          notifyerror("Feed error!");
        } else {
          setSafeamenities(data);
        }
      })
      .catch(function (error) {
        setLoadig(false);
        console.log(error);
      });
  };

  const handleSelect=(id)=>{
    let tmparry=selected
    
    if(tmparry.indexOf(id)==-1){
      setSelected([...tmparry,id])
    }else{
      const tarr = [...tmparry];
      tarr.splice(tmparry.indexOf(id), 1)
     
      setSelected(tarr)
    }
    
  }

  const loadlisting=()=>{
    setLoadig(true);

    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/hotel/single/" + id,
      headers: { 
        'Authorization': 'Bearer '+token
      },
    };

    axios(config)
      .then(function (response) {
        setLoadig(false);
        const data = response.data.hotel;
        if (data == "") {
          notifyerror("Invalid listing!");
       
        } else {
        
          let arr=JSON.parse(data.amenities)
          setSelected(arr);
        }
      })
      .catch(function (error) {
        setLoadig(false);
        // notifyerror("Connection error!");
        
      });
  }

  useEffect(() => {
    setLoadig(true);
    getAmenities(1);
    getSafeAmenities(2);
    loadlisting();
    
  }, []);

  const updateAmenities=()=>{

    var tdata = {
      'id' : id,
      'field' : 'amenities',
      'value' : JSON.stringify(selected)
    }

    var config = {
      method: 'patch',
      url: process.env.REACT_APP_API_URL + "/hotel/updatefield",
      headers: { 
        'Authorization': 'Bearer '+token,
      },
      data :tdata
    };
    
    axios(config)
    .then((response)=> {
      setLoadig(false);
      console.log(response.data);
      navigate('/addlisting/contact/'+id)
    })
    .catch(function (error) {
      console.log(error);
    });


  }

  let amenitieswithicon = amenities ? (

    amenities.map((data, i) => {
 
      if(selected.indexOf(data.id)!==-1){
       
        return (
          <div
            id={data.id}
            onClick={()=>handleSelect(data.id)}
            className="rounded-xl border-2 py-4 px-4 border-3 border-black transition duration-150"
          >
            <img src={`/images/amenities/${data.icon}`} className="mb-2 h-10" />
            <h2 className="font-semibold text-left">{data.name}</h2>
          </div>
        );
      }else{
      
        return (
          <div
            id={data.id}
            onClick={()=>handleSelect(data.id)}
            className="rounded-xl border-2 py-4 px-4 hover:border-3 hover:border-black hover:transition duration-150"
          >
            <img src={`/images/amenities/${data.icon}`} className="mb-2 h-10" />
            <h2 className="font-semibold text-left">{data.name}</h2>
          </div>
        );
      }
  
    
    })
  ) : (
    <span>No Data</span>
  );  
  
  let amenitieswithiconsafe = safeamenities ? (
    
    safeamenities.map((data, i) => {
    
      if(selected.indexOf(data.id)!==-1){
       
        return (
          <div
            id={data.id}
            onClick={()=>handleSelect(data.id)}
            className="rounded-xl border-2 py-4 px-4 border-3 border-black transition duration-150"
          >
            <img src={`/images/amenities/${data.icon}`} className="mb-2 h-10" />
            <h2 className="font-semibold text-left">{data.name}</h2>
          </div>
        );
      }else{
      
        return (
          <div
            id={data.id}
            onClick={()=>handleSelect(data.id)}
            className="rounded-xl border-2 py-4 px-4 hover:border-3 hover:border-black hover:transition duration-150"
          >
            <img src={`/images/amenities/${data.icon}`} className="mb-2 h-10" />
            <h2 className="font-semibold text-left">{data.name}</h2>
          </div>
        );
      }
  
    
    })
  ) : (
    <span>No Data</span>
  );

  return (
    <LoadingScreen loading={loading} bgColor="#ffffffcc" logoSrc={loader}>
      <body>
        <PropertyNav />

        <main>
          <div className="overflow-auto flex flex-col md:mt-[5%] md:ml-[20%] md:mr-[20%] mt-10 ml-7 mb-20">
            <div>
              <h1 className="font-medium text-3xl text-left">
                Tell guests what your place has to offer
              </h1>
            </div>
            <div></div>
            <h2 className="font-medium text-2xl text-left mt-8">
              Standout Amenities
            </h2>
            <div class="grid grid-cols-3 gap-2 mt-5 content-start">
              {amenitieswithicon ? amenitieswithicon : <span>Error!</span>}
            </div>
            <h2 className="font-medium text-2xl text-left mt-8">
            Safety Items
            </h2>
            <div class="grid grid-cols-3 gap-2 mt-5 content-start">
              {amenitieswithiconsafe ? amenitieswithiconsafe : <span>Error!</span>}
            </div>
          </div>
        </main>

        <div className="z-40 fixed bottom-0">
          <div>
            <div class=" h-2 w-full bg-neutral-200 dark:bg-neutral-400">
              <div class="h-2 bg-[#00008B]" style={{ width: "60%" }}></div>
            </div>
          </div>

          <div className="flex flex-row bg-white items-center justify-between  h-[60px] w-screen">
            <button onClick={() => navigate("/addlisting/surroundings/" + id)}  type="button" className="font-bold ml-10 underline">Back</button>
            <div class="mr-5">
              <button
                onClick={() => 
                  updateAmenities()
                }
                type="button"
                className="text-center p-2 rounded-2xl mt-3 w-[100px] text-white font-bold text-sm bg-[#00008b] hover:bg-[#050544] duration-300"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </body>
    </LoadingScreen>
  );
}
