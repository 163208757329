import Nav from "../shared/nav";
import LoadingScreen from "react-loading-screen";
import loader from "../../Resources/loader.gif";
import { useState } from "react";
import { useParams } from "react-router-dom";


export default function Managelisting() {

  const {id, name} = useParams();

  const [loading, setLoading] = useState(false);

  return (
    <LoadingScreen loading={loading} bgColor="#ffffffcc" logoSrc={loader}>
      <div className="flex md:flex-row flex-col bg-[#F8F8F8]">
        <Nav />

        {/* Start main content */}

        <div className="md:flex-1 flex-col md:ml-[250px] md:mt-50% md:mr-1 ml-6 mr-6">

        <h3 className="text-2xl mt-5 font-bold text-[#00008B] text-center">
            {name}
          </h3>

          <div class="flex flex-col w-full min-h-screen bg-[#ffffff]">
            <main class="flex flex-col gap-4 p-4 md:gap-8 md:p-10">
              <div class="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                <div
                  class="rounded-lg border bg-card text-card-foreground shadow-sm"
                  data-v0-t="card"
                >
                  <div class="p-6 flex flex-row items-center justify-between pb-2 space-y-0">
                    <h3 class="tracking-tight text-sm font-medium">
                      Manage Rooms
                    </h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="w-4 h-4 text-gray-500 dark:text-gray-400"
                    >
                      <rect
                        width="16"
                        height="20"
                        x="4"
                        y="2"
                        rx="2"
                        ry="2"
                      ></rect>
                      <path d="M9 22v-4h6v4"></path>
                      <path d="M8 6h.01"></path>
                      <path d="M16 6h.01"></path>
                      <path d="M12 6h.01"></path>
                      <path d="M12 10h.01"></path>
                      <path d="M12 14h.01"></path>
                      <path d="M16 10h.01"></path>
                      <path d="M16 14h.01"></path>
                      <path d="M8 10h.01"></path>
                      <path d="M8 14h.01"></path>
                    </svg>
                  </div>
                  <div class="p-6">
                    <div class="text-base font-bold">Manage all your rooms</div>
                    <a href={"/rooms/manage/" + id}  class="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 mt-4 bg-[#00008B] text-white">
                      Go to Rooms
                    </a>
                  </div>
                </div>
                <div
                  class="rounded-lg border bg-card text-card-foreground shadow-sm"
                  data-v0-t="card"
                >
                  <div class="p-6 flex flex-row items-center justify-between pb-2 space-y-0">
                    <h3 class="tracking-tight text-sm font-medium">
                      Manage Offers
                    </h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="w-4 h-4 text-gray-500 dark:text-gray-400"
                    >
                      <path d="M12 2H2v10l9.29 9.29c.94.94 2.48.94 3.42 0l6.58-6.58c.94-.94.94-2.48 0-3.42L12 2Z"></path>
                      <path d="M7 7h.01"></path>
                    </svg>
                  </div>
                  <div class="p-6">
                    <div class="text-base font-bold">
                      Manage all your offers
                    </div>
                    <a href={"/offers/manage/" + id + "/" + name}  class="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 mt-4 bg-[#00008B] text-white">
                      Go to Offers
                    </a>
                  </div>
                </div>
                <div
                  class="rounded-lg border bg-card text-card-foreground shadow-sm"
                  data-v0-t="card"
                >
                  <div class="p-6 flex flex-row items-center justify-between pb-2 space-y-0">
                    <h3 class="tracking-tight text-sm font-medium">
                      Manage Property Details
                    </h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="w-4 h-4 text-gray-500 dark:text-gray-400"
                    >
                      <rect
                        width="16"
                        height="20"
                        x="4"
                        y="2"
                        rx="2"
                        ry="2"
                      ></rect>
                      <path d="M9 22v-4h6v4"></path>
                      <path d="M8 6h.01"></path>
                      <path d="M16 6h.01"></path>
                      <path d="M12 6h.01"></path>
                      <path d="M12 10h.01"></path>
                      <path d="M12 14h.01"></path>
                      <path d="M16 10h.01"></path>
                      <path d="M16 14h.01"></path>
                      <path d="M8 10h.01"></path>
                      <path d="M8 14h.01"></path>
                    </svg>
                  </div>
                  <div class="p-6">
                    <div class="text-base font-bold">
                    Manage all details related to listing
                    </div>
                    <button class="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 mt-4 bg-[#00008B] text-white">
                      Go to Property details
                    </button>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>

        {/* End main content */}
      </div>
    </LoadingScreen>
  );
}
