import PropertyNav from "./nav";
import { useNavigate } from "react-router-dom";


export default function PropertyThankyou() {
  let navigate = useNavigate();

  return (
    <body>
      <PropertyNav />

      <main>
        <div className="flex flex-col md:mt-[15%] md:ml-[25%] md:mr-[25%] mt-10 ml-7">
          <div>
            <h1 className="font-medium text-3xl ">
                Thank you submitting your property with us
            </h1>
            <h3 className="font-medium text-2xl mt-2">Will review your listing and get back to you... Mean time browse through our
            <a href="/feed" className="font-medium text-2xl text-[#00008B]"> FEED</a> </h3>
          </div>
          <div></div>

          <div className="flex flex-col">
        

      
          </div>
        </div>
      </main>

      <div className="z-40 fixed bottom-0">
        <div>
          <div class="mb-2 h-2 w-full bg-neutral-200 dark:bg-neutral-400">
            <div class="h-2 bg-[#00008B]" style={{ width: "100%" }}></div>
          </div>
        </div>

        <div className="flex flex-row items-center justify-between  h-[60px] w-screen">
          <a href="rules" className="font-bold ml-10 underline">Back</a>
          {/* <div class="mr-5">
            <button
              onClick={() => navigate("/addlisting/rules")}
              type="button"
              className="text-center p-2 rounded-2xl mt-3 w-[100px] text-white font-bold text-sm bg-[#00008b] hover:bg-[#050544] duration-300"
            >
              Next
            </button>
          </div> */}
        </div>
      </div>
    </body>
  );
}
