import PropertyNav from "./nav";
import { useNavigate, useParams } from "react-router-dom";
import { useToken } from "../../../auth/useToken";
import { useState, useEffect } from "react";
import LoadingScreen from "react-loading-screen";
import loader from "../../../Resources/loader.gif";
import axios from "axios";
import { toast } from "react-toastify";

export default function PropertyContact() {
  let navigate = useNavigate();

  let { id } = useParams();

  const [token, setToken] = useToken();
  const [loading, setLoadig] = useState(false);
  const [contacts, setContacts] = useState({
    hotline: "",
    phone2: "",
    whatsapp: "",
    email: "",
  });

  const notifyerror = (msg) => toast.error(msg);

  const loadlisting = () => {
    setLoadig(true);

    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/hotel/single/" + id,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        setLoadig(false);
        const data = response.data.hotel;
        if (data == "") {
          notifyerror("Invalid listing!");
        } else {

          let contactData = {
            hotline: response.data.hotel.hotline,
            phone2: response.data.hotel.phone2,
            whatsapp: response.data.hotel.whatsapp,
            email:response.data.hotel.email ,
          };
          setContacts(contactData);
        }
      })
      .catch(function (error) {
        setLoadig(false);
        notifyerror("Connection error!");
      });
  };

  useEffect(()=>{
    loadlisting();
  },[])

  const updateContacts = () => {

    var tdata = {
      'hotline' : contacts.hotline,
      'phone2' :    contacts.phone2,
      'whatsapp' :  contacts.whatsapp,
      'email' :  contacts.email,
    }

    var config = {
      method: "patch",
      url: process.env.REACT_APP_API_URL + "/hotel/updatecontacts/" + id,
      headers: {
        Authorization: "Bearer " + token,
      },
      data :tdata
    };

    axios(config)
      .then((response) => {
        setLoadig(false);
        
        navigate("/addlisting/rules/" + id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <LoadingScreen loading={loading} bgColor="#ffffffcc" logoSrc={loader}>
      <body>
        <PropertyNav />

        <main>
          <div className="flex flex-col md:mt-[5%] md:ml-[25%] md:mr-[25%] mt-10 ml-7">
            <div>
              <h1 className="font-medium text-3xl text-left">
                How guests can contact you?
              </h1>
            </div>
            <div></div>

            <div className="flex flex-col">
              <div className="mt-5">
                <div className="flex flex-row">
                  <div className="flex flex-col w-full">
                    <div className="mr-2 relative group ">
                      <input
                        type="tel"
                        id="hotline"
                        required
                        value={contacts.hotline}
                        onChange={(e)=>{
                          setContacts(prevState => ({
                            ...prevState,
                            hotline: e.target.value
                          }))
                       
                        }}
                        className="rounded-lg w-full h-10 p-6 peer border-2 border-gray-300"
                      />
                      <label
                        for="hotline"
                        className="transform transition-all absolute top-0 left-0 h-full flex items-center pl-2 text-medium group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0"
                      >
                        Contact Number 1
                      </label>
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <div className=" relative group ">
                      <input
                        type="tel"
                        id="Contact"
                        required
                        value={contacts.phone2}
                        onChange={(e)=>{
                          setContacts(prevState => ({
                            ...prevState,
                            phone2: e.target.value
                          }))
                       
                        }}
                        className="rounded-lg w-full h-10 p-6 peer border-2 border-gray-300"
                      />
                      <label
                        for="Contact"
                        className="transform transition-all absolute top-0 left-0 h-full flex items-center pl-2 text-medium group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0"
                      >
                        Contact Number 2
                      </label>
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex flex-row">
                  <div className="flex flex-col w-full">
                    <div className="mr-2 relative group ">
                      <input
                        type="tel"
                        id="whatsapp"
                        value={contacts.whatsapp}
                        onChange={(e)=>{
                          setContacts(prevState => ({
                            ...prevState,
                            whatsapp: e.target.value
                          }))
                       
                        }}
                        required
                        className="rounded-lg w-full h-10 p-6 peer border-2 border-gray-300"
                      />
                      <label
                        for="whatsapp"
                        className="transform transition-all absolute top-0 left-0 h-full flex items-center pl-2 text-medium group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0"
                      >
                        WhatsApp Number
                      </label>
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <div className=" relative group ">
                      <input
                        type="email"
                        id="email"
                        value={contacts.email}
                        onChange={(e)=>{
                          setContacts(prevState => ({
                            ...prevState,
                            email: e.target.value
                          }))
                       
                        }}
                        required
                        className="rounded-lg w-full h-10 p-6 peer border-2 border-gray-300"
                      />
                      <label
                        for="email"
                        className="transform transition-all absolute top-0 left-0 h-full flex items-center pl-2 text-medium group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0"
                      >
                        Email address
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <div className="z-40 fixed bottom-0">
          <div>
            <div class="mb-2 h-2 w-full bg-neutral-200 dark:bg-neutral-400">
              <div class="h-2 bg-[#00008B]" style={{ width: "80%" }}></div>
            </div>
          </div>

          <div className="flex flex-row items-center justify-between  h-[60px] w-screen">
            <button
              onClick={() => navigate("/addlisting/amenities/" + id)}
              type="button"
              className="font-bold ml-10 underline"
            >
              Back
            </button>
            <div class="mr-5">
              <button
                onClick={() =>updateContacts()}
                type="button"
                className="text-center p-2 rounded-2xl mt-3 w-[100px] text-white font-bold text-sm bg-[#00008b] hover:bg-[#050544] duration-300"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </body>
    </LoadingScreen>
  );
}
