import { useNavigate, useParams } from "react-router-dom";
import PropertyNav from "./nav";
import { useToken } from "../../../auth/useToken";
import { useState, useEffect } from "react";
import LoadingScreen from "react-loading-screen";
import loader from "../../../Resources/loader.gif";
import axios from "axios";
import { toast } from "react-toastify";

export default function PropertyType() {

  let navigate = useNavigate();

  let { id } = useParams(); 

  const [token, setToken] = useToken();
  const [loading, setLoadig] = useState(false);
  const [hoteltypes, setHoteltypes] = useState();
  const [selected, setSelected] = useState();

  const notifyerror = (msg) => toast.error(msg);
  const notifysuccess = (msg) => toast.success(msg);

  const loadlisting=()=>{
    setLoadig(true);

    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/hotel/single/" + id,
      headers: { 
        'Authorization': 'Bearer '+token
      },
    };

    axios(config)
      .then(function (response) {
        setLoadig(false);
      
        if (response.data.success === false) {
          notifyerror("Invalid listing!");
          navigate('/mylistings')
        } else {
        
          setSelected(response.data.hotel.hotel_type_id);
        }
      })
      .catch(function (error) {
        setLoadig(false);
        notifyerror("Connection error!");
        
      });
  }

  const htypes = () => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/hotel/hoteltypes",
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        setLoadig(false);
        const data = response.data;
        if (data == "") {
          notifyerror("Feed error!");
        } else {
          setHoteltypes(response.data.hoteltypes);
        }
      })
      .catch(function (error) {
        setLoadig(false);
        console.log(error);
      });
  };

  useEffect(() => {
    setLoadig(true);
    htypes();
    loadlisting();
  }, []);

  const update=()=>{

    const encodedParams = new URLSearchParams();
    encodedParams.set('id', id);
    encodedParams.set('field', 'hotel_type_id');
    encodedParams.set('value', selected);
    
    var config = {
      method: 'patch',
      url: process.env.REACT_APP_API_URL + "/hotel/updatefield",
      headers: { 
        'Authorization': 'Bearer '+token,
      },
      data : encodedParams
    
    };
    
    axios(config)
    .then((response)=> {
      setLoadig(false);
      console.log(response);
      navigate('/addlisting/address/'+id)
    })
    .catch(function (error) {
      console.log(error);
    });


  }


  let htypeswithicon = hoteltypes ? (
    hoteltypes.map((data, i) => {
      if(data.id==selected){
        return(
          <div className="rounded-xl border-2 py-4 px-4 border-3 border-black hover:transition duration-150">
          <img src={`/images/types/${data.icon}`} className="mb-2 h-10" />
          <h2 className="font-semibold text-left">{data.type}</h2>
        </div>
        )
      }else{
        return (
          <div onClick={()=>setSelected(data.id)} className="rounded-xl border-2 py-4 px-4 hover:border-3 hover:border-black hover:transition duration-150">
            <img src={`/images/types/${data.icon}`} className="mb-2 h-10" />
            <h2 className="font-semibold text-left">{data.type}</h2>
          </div>
        );
      }

    })
  ) : (
    <span>No Data</span>
  );

  return (
    <LoadingScreen loading={loading} bgColor="#ffffffcc" logoSrc={loader}>
      <body>
        <PropertyNav />

        <main>
          <div className="overflow-auto flex flex-col md:mt-[5%] md:ml-[20%] md:mr-[20%] mt-10 ml-7 mb-20">
            <div>
              <h1 className="font-medium text-3xl text-left">
                Which of these best <br /> describes your property?
              </h1>
            </div>
            <div>
      
            </div>

            <div class="grid grid-cols-3 gap-2 mt-5 content-start">
              {htypeswithicon ? htypeswithicon : <span>Error!</span>}
            </div>
          </div>
        </main>

        <div className="z-40 fixed bottom-0">
          <div>
            <div class=" h-2 w-full bg-neutral-200 dark:bg-neutral-400">
              <div class="h-2 bg-[#00008B]" style={{ width: "20%" }}></div>
            </div>
          </div>

          <div className="flex flex-row bg-white items-center justify-between  h-[60px] w-screen">
            <button onClick={() => navigate("/addlisting/updatepropertyname/" + id)}  type="button" className="font-bold ml-10 underline">Back</button>
            <div class="mr-5">
              <button
                onClick={() => update()}
                type="button"
                className="text-center p-2 rounded-2xl mt-3 w-[100px] text-white font-bold text-sm bg-[#00008b] hover:bg-[#050544] duration-300"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </body>
    </LoadingScreen>
  );
}
