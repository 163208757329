import { useNavigate } from "react-router-dom";


export default function PropertyNav( {id, name} ){
  let navigate = useNavigate(); 
  
    return(
      <div className="z-40 flex flex-row items-center justify-between bg-[#00008B] h-[60px] w-screen">
      <img src="/images/logomain.svg" class="w-[100px] ml-5" alt="fleeinn" />
      <div class="mr-5">
        <button
        onClick={()=>navigate('/mylistings')}
          type="button"
          class="z-40 font-medium bg-gray-200 w-[50px] rounded-2xl py-1 block focus outline-none hover:bg-[#00008B] hover:text-white hover:border-2 hover:border-white hover:duration-100"
        >
          Exit
        </button>
      </div>
    </div>
    );
}

