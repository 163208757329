export default function Nav() {
  const btn = document.getElementById("menu-btn");
  const menu = document.getElementById("menu");

  function navToggle() {
    console.log(menu);
    btn.classList.toggle("open");
    menu.classList.toggle("flex");
    menu.classList.toggle("hidden");
  }

  return (
    <div>
      {/* <!-- Mobile Menu Start --> */}

      <div
        id="menu"
        class="z-40 absolute hidden top-0 bottom-0 left-0 flex-col self-end w-full min-screen py-1 pt-28 pl-12 space-y-3 text-lg text-white bg-[#00008b]"
      >
         <a href="/feed"><img src="/images/logomain.svg" class="w-[100px] ml-5" alt="fleeinn" /></a>
        <a href="/feed" class="hover:text-ping-500">
          Home
        </a>
        <a href="#" class="hover:text-ping-500">
          Manage Properties
        </a>
 
      </div>

      {/* <!-- Mobile Menu Stop --> */}

      {/* <!-- Mobile header with menu button start --> */}

      <div class="z-40 flex flex-row items-center justify-between md:hidden bg-[#00008B] h-[60px] w-screen">
        <img src="/images/logomain.svg" class="w-[100px] ml-5" alt="fleeinn" />
        <div class="md:hidden mr-5 mt-3">
          <button
            onClick={navToggle}
            id="menu-btn"
            type="button"
            class="z-40 block hamburger md:hidden focus outline-none"
          >
            <span class="hamburger-top"></span>
            <span class="hamburger-middle"></span>
            <span class="hamburger-bottom"></span>
          </button>
        </div>
      </div>

      {/* <!-- Mobile header with menu button end --> */}

      {/* <!--Sidebar start--> */}
      <aside className="md:block hidden md:w-64 md:fixed md:left-0 md:top-0 md:h-screen md:rounded-l-none md:rounded-r-2xl md:rounded-b-none md:bg-[#00008B]">
        <div>
          <img
            src="/images/logomain.svg"
            className="p-2 mt-5 w-[200px] mx-auto"
            alt="freeinn"
          />

          <div className="mt-6">
            <div className="flex flex-row mt-4 justify-center">
              <span className="mr-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25.432 28.036"
                >
                  <g
                    id="Icon_feather-home"
                    data-name="Icon feather-home"
                    transform="translate(-3.5 -2)"
                  >
                    <path
                      id="Path_140"
                      data-name="Path 140"
                      d="M4.5,12.113,16.216,3l11.716,9.113v14.32a2.6,2.6,0,0,1-2.6,2.6H7.1a2.6,2.6,0,0,1-2.6-2.6Z"
                      fill="#fff"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Path_141"
                      data-name="Path 141"
                      d="M13.5,31.018V18h7.811V31.018"
                      transform="translate(-1.189 -1.982)"
                      fill="#fff"
                      stroke="#00008b"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </span>

              <a
                href="/feed"
                className="font-bold hover:border-b-4 text-white text-xl hover:duration-100"
              >
                Home
              </a>
            </div>
            <div className="flex flex-row mt-4 justify-center">
              <span className="mr-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25.432 28.036"
                >
                  <g
                    id="Icon_feather-home"
                    data-name="Icon feather-home"
                    transform="translate(-3.5 -2)"
                  >
                    <path
                      id="Path_140"
                      data-name="Path 140"
                      d="M4.5,12.113,16.216,3l11.716,9.113v14.32a2.6,2.6,0,0,1-2.6,2.6H7.1a2.6,2.6,0,0,1-2.6-2.6Z"
                      fill="#fff"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Path_141"
                      data-name="Path 141"
                      d="M13.5,31.018V18h7.811V31.018"
                      transform="translate(-1.189 -1.982)"
                      fill="#fff"
                      stroke="#00008b"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </span>

              <a
                href="/listings"
                className="font-bold hover:border-b-4 text-white text-xl hover:duration-100"
              >
                Add Listings
              </a>
            </div>         <div className="flex flex-row mt-4 justify-center">
              <span className="mr-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25.432 28.036"
                >
                  <g
                    id="Icon_feather-home"
                    data-name="Icon feather-home"
                    transform="translate(-3.5 -2)"
                  >
                    <path
                      id="Path_140"
                      data-name="Path 140"
                      d="M4.5,12.113,16.216,3l11.716,9.113v14.32a2.6,2.6,0,0,1-2.6,2.6H7.1a2.6,2.6,0,0,1-2.6-2.6Z"
                      fill="#fff"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Path_141"
                      data-name="Path 141"
                      d="M13.5,31.018V18h7.811V31.018"
                      transform="translate(-1.189 -1.982)"
                      fill="#fff"
                      stroke="#00008b"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </span>

              <a
                href="/mylistings"
                className="font-bold hover:border-b-4 text-white text-xl hover:duration-100"
              >
                My Listings
              </a>
            </div>

       
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="absolute bottom-0 right-12 text-center text-xs font-mono mb-8 text-white">
            <span>
              Copyright &copy; {(new Date().getFullYear())} fleeinn
              <br />
            </span>
            <span>All rights reserved</span>
          </div>
        </div>
      </aside>
    </div>
  );
}
