import LoadingScreen from "react-loading-screen";
import loader from "../../Resources/loader.gif";
import { useState, Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import PropertyNav from "../listing/newproperty/nav";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { useToken } from "../../auth/useToken";
import { nanoid } from "nanoid";

export default function ManageOffers() {


  const { id, name } = useParams();

  const [offers, setOffers] = useState();

  const [offer, setOffer] = useState();

  const [loading, setLoading] = useState(false);

  const [token] = useToken();

  let [isOpen, setIsOpen] = useState(false);

  let [isEditOpen, setEditIsOpen] = useState(false);

  const notifysuccess = (msg) => toast.success(msg);
  const notifyerror = (msg) => toast.error(msg);


  const getoffers = () => {
    setLoading(true);
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/offer/byhotel/" + id,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        const data = response.data.offers;
        if (data === "") {
          notifyerror("Invalid offers!");
        } else {
          notifysuccess("Offers loaded!");

          setOffers(data);
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
        notifyerror("Connection error!");
      });
  };

  const getoffer = (id) => {
    setLoading(true);
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/offer/byhotel/" + id,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        const data = response.data;
        if (data === "") {
          notifyerror("Invalid offer!");
        } else {
          setOffer(response.data);
          setLoading(false);
          setEditIsOpen(true);
        }
      })
      .catch(function (error) {
        setLoading(false);
        notifyerror("Connection error!");
      });
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
   
    let pdate = {
      title: data.title,
      content: data.content,
      img: data.img[0],
      expiredate: data.expiredate,
      hotel_id: id,
    };

    var config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/offer",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",  
      },
      data: pdate,
    };

    await axios(config)
      .then((response) => {
        setIsOpen(false);
        getoffers();
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
    
  };


  // const updateoffer = (data) => {

  //   let pdate = {
  //     id: offer.id,
  //     title: data.title,
  //     content: data.content,
  //     img: filename,
  //     expiredate: data.expiredate,
  //     hotelid: id,
  //   };

  //   var config = {
  //     method: "put",
  //     url: process.env.REACT_APP_API_URL + "/Offer",
  //     headers: {
  //       Authorization: "Bearer " + token,
  //       "Content-Type": "application/json",
  //     },
  //     data: pdate,
  //   };

  //   axios(config)
  //     .then((response) => {
  //       notifysuccess("Offer updated!");
  //       setEditIsOpen(false);
  //       getoffers();
  //       setLoading(false);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const deleteoffer = (id) => {
    
    var config = {
      method: "delete",
      url: process.env.REACT_APP_API_URL + "/offer/" + id,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response) => {
        notifysuccess("Offer Deleted!");
        setIsOpen(false);
        getoffers();
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getoffers();
  }, []);

  let offerslist = offers ? (
    offers.map((data, i) => {
      return (
        <div className="md:w-1/3 bg-white shadow-2xl">
          <div className="relative">
            <img
              src={process.env.REACT_APP_S3_URL + "/offers/" + data.img}
              alt=""
              className="h-[450px] object-contain w-full"
            />

            <span className="absolute top-0 right-0 p-2 rounded-l-2xl mt-10 text-white bg-[#8b000e]">
              {data.expiredate}
            </span>
          </div>
          <h3 className="text-2xl mt-1 font-bold text-[#00008B] text-center">
            {data.title}
          </h3>

          <p className="mb-4 ml-3 mt-1 text-center">{data.content}</p>

          <hr />

          <div className="px-6 pt-4 pb-2">
            <button
              onClick={() => getoffer(data.id)}
              className="inline-block bg-gray-300 rounded px-3 py-1 font-semibold text-gray-700 mr-2 mb-2 hover:bg-[#00008B] hover:text-white"
            >
              Edit
            </button>

            <button
              onClick={() => deleteoffer(data.id)}
              className="inline-block bg-red-600 rounded px-3 py-1 font-semibold text-white mr-2 mb-2 hover:bg-red-400 hover:text-black"
            >
              Delete
            </button>
          </div>
        </div>
      );
    })
  ) : (
    <span>No Data</span>
  );

  return (
    <LoadingScreen loading={loading} bgColor="#ffffffcc" logoSrc={loader}>
      <body>
        <PropertyNav id={id} name={name} />

        <button
          onClick={openModal}
          className="mt-3 items-center w-md rounded px-3 py-1 font-semibold text-white mr-2 mb-2 bg-[#00008B] hover:bg-blue-900 hover:text-white"
        >
          Add an offer
        </button>

        <div className="m-5 flex md:flex-row flex-col colums-3 gap-3">
          {/* <!-- Col1 start --> */}

          {offerslist ? offerslist : <span>No Data</span>}
        </div>

        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    {/* Offer form  */}

                    <div className="flex items-center m-5">
                      <div className="pr-2 text-gray-800 font-bold text-2xl">
                        Add an offer
                      </div>
                      <div className="border-t border-4 border-[#00008B] flex-grow"></div>

                      <div className="border-t border-4 border-[#00008B] flex-grow"></div>
                    </div>

                    <div className="mt-5">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-row">
                          <div className="flex w-full flex-col">
                            <div className="ml-5 mr-5 mt-2 text-left">
                              <label>Property Name</label>
                              <input
                                className="mt-2 p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                type="text"
                                disabled
                                value={name}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-row">
                          <div className="flex w-full flex-col">
                            <div className="ml-5 mr-5 mt-2 text-left">
                              <label>Offer Title</label>
                              <input
                                {...register("title", { required: true })}
                                className="mt-2 p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                type="text"
                              />
                              {errors.name && (
                                <span className="text-red-700 font-bold">
                                  This field is required *{" "}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-row">
                          <div className="flex w-full flex-col">
                            <div className="ml-5 mr-5 mt-2 text-left">
                              <label>Offer Description</label>
                              <textarea
                                {...register("content", { required: true })}
                                rows="8"
                                className="mt-2 p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                type="text"
                              ></textarea>
                              {errors.name && (
                                <span className="text-red-700 font-bold">
                                  This field is required *{" "}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-row">
                          <div className="flex w-full flex-col">
                            <div className="ml-5 mr-5 mt-2 text-left">
                              <label className="mt-2">Upload offer image</label>
                              <input
                                {...register("img", { required: true })}
                                className="mt-2 p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                aria-describedby="file_input_help"
                                accept="image/png, image/jpeg"
                                type="file"
                            
                              />
                              <p
                                className="mt- text-sm text-gray-500 dark:text-gray-300"
                                id="file_input_help"
                              >
                                PNG, JPG.
                              </p>
                              {errors.img && (
                                <span className="text-red-700 font-bold">
                                  This field is required *{" "}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-row mb-2">
                          <div className="flex w-full flex-col">
                            <div className="ml-5 mr-5 mt-2 text-left">
                              <label>Expire date</label>
                              <input
                                {...register("expiredate", { required: true })}
                                className="mt-2 p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                type="date"
                              />
                              {errors.expiredate && (
                                <span className="text-red-700 font-bold">
                                  This field is required *{" "}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <hr />

                        <div className="flex flex-row m-5">
                          <button
                            onClick={closeModal}
                            className="flex flex-col items-center w-full bg-red-600 rounded px-3 py-1 font-semibold text-white mr-2 mb-2 hover:bg-red-400 hover:text-black"
                          >
                            Cancel
                          </button>

                          <input
                            type="submit"
                            className="flex flex-col items-center w-full rounded px-3 py-1 font-semibold text-white mr-2 mb-2 bg-[#00008B] hover:bg-blue-900 hover:text-white"
                            value="Submit offer"
                          />
                        </div>
                      </form>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>

        <Transition appear show={isEditOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    {/* Offer form  */}

                    <div className="flex items-center m-5">
                      <div className="pr-2 text-gray-800 font-bold text-2xl">
                        Edit an offer
                      </div>
                      <div className="border-t border-4 border-[#00008B] flex-grow"></div>

                      <div className="border-t border-4 border-[#00008B] flex-grow"></div>
                    </div>

                    <div className="mt-5">
                      <form >
                      {/* <form onSubmit={handleSubmit(onEditSubmit)}>/ */}
                        <div className="flex flex-row">
                          <div className="flex w-full flex-col">
                            <div className="ml-5 mr-5 mt-2 text-left">
                              <label className="">Property Name</label>
                              <input
                                className="mt-2 p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                type="text"
                                disabled
                                value={name}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-row">
                          <div className="flex w-full flex-col">
                            <div className="ml-5 mr-5 mt-2 text-left">
                              <label className="">Offer Title</label>
                              <input
                                {...register("title", { required: true })}
                                className="mt-2 p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                type="text"
                                value={offer?.title}
                                onChange={(e) => {
                                  setOffer((prevState) => ({
                                    ...prevState,
                                    title: e.target.value,
                                  }));
                                }}
                              />
                              {errors.name && (
                                <span className="text-red-700 font-bold">
                                  This field is required *{" "}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-row">
                          <div className="flex w-full flex-col">
                            <div className="ml-5 mr-5 mt-2 text-left">
                              <label className="">Offer Description</label>
                              <textarea
                                {...register("content", { required: true })}
                                rows="8"
                                className="mt-2 p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                type="text"
                                value={offer?.content}
                                onChange={(e) => {
                                  setOffer((prevState) => ({
                                    ...prevState,
                                    content: e.target.value,
                                  }));
                                }}
                              ></textarea>
                              {errors.name && (
                                <span className="text-red-700 font-bold">
                                  This field is required *{" "}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-row">
                          <div className="flex w-full flex-col">
                            <div className="ml-5 mr-5 mt-2 text-left">
                              <label className="mt-2">Upload offer image</label>
                              <input
                                {...register("img", { required: true })}
                                className="mt-2 p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                aria-describedby="file_input_help"
                                accept="image/png, image/jpeg"
                                type="file"

                              />
                              <p
                                className="mt- text-sm text-gray-500 dark:text-gray-300"
                                id="file_input_help"
                              >
                                PNG, JPG .
                              </p>
                              {errors.img && (
                                <span className="text-red-700 font-bold">
                                  This field is required *{" "}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-row mb-2">
                          <div className="flex w-full flex-col">
                            <div className="ml-5 mr-5 mt-2 text-left">
                              <label className="">Expire date</label>
                              <input
                                {...register("expiredate", { required: true })}
                                className="mt-2 p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                type="date"
                                value={offer?.expiredate}
                                onChange={(e) => {
                                  setOffer((prevState) => ({
                                    ...prevState,
                                    expiredate: e.target.value,
                                  }));
                                }}
                              />
                              {errors.expiredate && (
                                <span className="text-red-700 font-bold">
                                  This field is required *{" "}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <hr />

                        <div className="flex flex-row m-5">
                          <button
                            onClick={() => setEditIsOpen(false)}
                            className="flex flex-col items-center w-full bg-red-600 rounded px-3 py-1 font-semibold text-white mr-2 mb-2 hover:bg-red-400 hover:text-black"
                          >
                            Cancel
                          </button>

                          <input
                            type="submit"
                            className="flex flex-col items-center w-full rounded px-3 py-1 font-semibold text-white mr-2 mb-2 bg-[#00008B] hover:bg-blue-900 hover:text-white"
                            value="Update offer"
                          />
                        </div>
                      </form>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </body>
    </LoadingScreen>
  );
}
