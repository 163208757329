import { useState, useEffect } from "react";
import PropertyNav from "./nav";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import loader from "../../../Resources/loader.gif";
import { useToken } from "../../../auth/useToken";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

export default function PropertyName() {
  
  let navigate=useNavigate();

  let { id } = useParams(); 

  const [token] = useToken();
  const [name, setName] = useState('');
  const [loading, setLoadig] = useState(false);
  
  const notifysuccess = (msg) => toast.success(msg);
  const notifyerror = (msg) => toast.error(msg);

  const loadlisting=()=>{
    setLoadig(true);

    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/hotel/single/" + id,
      headers: { 
        'Authorization': 'Bearer '+token
      },
    };

    axios(config)
      .then(function (response) {
        setLoadig(false);
        const data = response.data;
        if (data == "") {
          notifyerror("Invalid listing!");
       
        } else {
          notifysuccess("Listing loaded!");
          setName(response.data.hotel.name);
        }
      })
      .catch(function (error) {
        setLoadig(false);
        notifyerror("Connection error!");
        
      });
  }

  const updateName=()=>{


    var tdata = {
      'id' : id,
      'field' : 'name',
      'value' : name
    }

    var config = {
      method: 'patch',
      url: process.env.REACT_APP_API_URL + "/hotel/updatefield",
      headers: { 
        'Authorization': 'Bearer '+token,
      },
      data :tdata
    };
    
    axios(config)
    .then((response)=> {
      setLoadig(false);
      navigate('/addlisting/propertytype/'+id)
    })
    .catch(function (error) {
      console.log(error);
    });


  }

  const addname=()=>{

    var data = JSON.stringify({
      "name": name
    });

    var config = {
      method: 'post',
      url: process.env.REACT_APP_API_URL + "/hotel",
      headers: { 
        'Authorization': 'Bearer '+token,
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then((response)=> {
      setLoadig(true);
      notifysuccess("Property name added!");
      navigate('/addlisting/propertytype/'+response.data.id)
    })
    .catch(function (error) {
      console.log(error);
    });


  }

  useEffect(()=>{

    if(!!id){
      loadlisting();
    }
    

  },[])

    return (
      <LoadingScreen loading={loading} bgColor="#ffffffcc" logoSrc={loader}>
      <body>

      <PropertyNav/>
        <main>
          <div className="flex flex-col md:mt-[8%] md:ml-[25%] md:mr-[25%] mt-10 ml-7">
            <div>
              <h1 className="font-medium text-3xl text-left">
                What is the name of your property?
              </h1>
            </div>
            <div>

            </div>
  
            <div className="flex-col mr-2 mt-3">
              <input
                type="text"
                required
                value={name}
                onChange={(e)=>setName(e.target.value)}
                placeholder="Name of your property"
                name="name"
                className="p-5 mt-5 rounded-2xl w-full placeholder:font-thin font-bold border-2 border-[#00008B] caret-[#00008b] placeholder-gray-400 focus:shadow-md outline-none w-[350px] "
              />
            </div>
          </div>
        </main>
  
        <div className="z-40 fixed bottom-0">
          <div>
            <div class=" h-2 w-full bg-neutral-200 dark:bg-neutral-400">
              <div class="h-2 bg-[#00008B]" style={{ width: "10%" }}></div>
            </div>
          </div>
  
          <div className="flex flex-row bg-white items-center justify-between  h-[60px] w-screen">
            <a href="/listings" className="font-bold ml-10 underline">Back</a>
            <div class="mr-5">
              <button
          
              onClick={()=>{
                console.log(id);
                if(id>1){
                  updateName()
                }else{
                  addname()
                }
          
              }}
               
              type="button"
                className="text-center p-2 rounded-2xl mt-3 w-[100px] text-white font-bold text-sm bg-[#00008b] hover:bg-[#050544] duration-300"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </body>
      </LoadingScreen>
    );
  }
  