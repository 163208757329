import PropertyNav from "./nav";
import { useNavigate, useParams } from "react-router-dom";
import LoadingScreen from "react-loading-screen";
import loader from "../../../Resources/loader.gif";
import { useToken } from "../../../auth/useToken";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";

export default function PropertySurroundings() {
  let navigate = useNavigate();

  let { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();
  const [landmarks, setLandmarks] = useState({
    'landmark1':'',
    'landmark2':'',
    'landmark3':'',
  });

  const notifyerror = (msg) => toast.error(msg);
  const notifysuccess = (msg) => toast.success(msg);

  const loadlisting = async () => {
    setLoading(true);

    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/hotel/single/" + id,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    await axios(config)
      .then(function (response) {
        setLoading(false);
        const data = response.data;
        if (data == "") {
          notifyerror("Invalid listing!");
        } else {
         
          notifysuccess("Listing loaded!");

          let landmarkdata= {
            'landmark1':response.data.hotel.landmark1,
            'landmark2':response.data.hotel.landmark2,
            'landmark3':response.data.hotel.landmark3,
          }
          console.log(response.data);
          setLandmarks(landmarkdata);
        }
      })
      .catch(function (error) {
        setLoading(false);
        notifyerror("Connection error!");
      });
  };

  useEffect(() => {
    setLoading(true);
    loadlisting();
  }, []);

  const updateLandmarks=()=>{

    
    var tdata = {
      'landmark1' : landmarks.landmark1,
      'landmark2' :    landmarks.landmark2,
      'landmark3' :  landmarks.landmark3
    }


    var config = {
      method: 'patch',
      url: process.env.REACT_APP_API_URL + "/hotel/updatelandmarks/" + id,
      headers: { 
        'Authorization': 'Bearer '+token,
      },
      data :tdata
    
    };
    
    axios(config)
    .then((response)=> {
      setLoading(false);
      console.log(response.data);
      navigate('/addlisting/amenities/'+id)
    })
    .catch(function (error) {
      console.log(error);
    });


  }

  return (
    <LoadingScreen loading={loading} bgColor="#ffffffcc" logoSrc={loader}>
      <body>
        <PropertyNav />

        <main>
          <div className="flex flex-col md:mt-[5%] md:ml-[25%] md:mr-[25%] mt-10 ml-7">
            <div>
              <h1 className="font-medium text-3xl text-left">
                What are the landmarks around your property?
              </h1>
            </div>
            <div></div>

            <div className="flex flex-col">
              <div className="mt-8">
                <div className="w-full relative group ">
                  <input
                    type="text"
                    id="landmark1"
                    value={landmarks.landmark1}
                    required
                    onChange={(e)=>{
                      setLandmarks(prevState => ({
                        ...prevState,
                        landmark1: e.target.value
                      })) 
                    }}
                    className="caret-[#00008b] rounded-lg w-full h-10 p-6 peer border-2 focus:border-[#00008B] focus:outline-none"
                  />
                  <label
                    for="landmark1"
                    className="transform transition-all absolute top-0 left-0 h-full flex items-center pl-2 text-medium group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0"
                  >
                    Landmark 1
                  </label>
                </div>

                <div className="mt-8 w-full relative group ">
                  <input
                    type="text"
                    id="landmark2"
                    value={landmarks.landmark2}
                    onChange={(e)=>{
                      setLandmarks(prevState => ({
                        ...prevState,
                        landmark2: e.target.value
                      })) 
                    }}
                    required
                    className="rounded-lg w-full h-10 p-6 peer border-2 focus:border-[#00008B] focus:outline-none"
                  />
                  <label
                    for="landmark2"
                    className="transform transition-all absolute top-0 left-0 h-full flex items-center pl-2 text-medium group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0"
                  >
                    Landmark 2
                  </label>
                </div>

                <div className="mt-8 w-full relative group ">
                  <input
                    type="text"
                    value={landmarks.landmark3}
                    onChange={(e)=>{
                      setLandmarks(prevState => ({
                        ...prevState,
                        landmark3: e.target.value
                      })) 
                    }}
                    id="landmark3"
                    required
                    className="rounded-lg w-full h-10 p-6 peer border-2 focus:border-[#00008B] focus:outline-none"
                  />
                  <label
                    for="landmark3"
                    className="transform transition-all absolute top-0 left-0 h-full flex items-center pl-2 text-medium group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0"
                  >
                    Landmark 3
                  </label>
                </div>
              </div>
            </div>
          </div>
        </main>

        <div className="z-40 fixed bottom-0">
          <div>
            <div class="mb-2 h-2 w-full bg-neutral-200 dark:bg-neutral-400">
              <div class="h-2 bg-[#00008B]" style={{ width: "50%" }}></div>
            </div>
          </div>

          <div className="flex flex-row items-center justify-between  h-[60px] w-screen">
            <button onClick={() => navigate("/addlisting/map/" + id)}  type="button" className="font-bold ml-10 underline">
              Back
            </button>
            <div class="mr-5">
              <button
                 onClick={() =>updateLandmarks()}
                type="button"
                className="text-center p-2 rounded-2xl mt-3 w-[100px] text-white font-bold text-sm bg-[#00008b] hover:bg-[#050544] duration-300"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </body>
    </LoadingScreen>
  );
}
