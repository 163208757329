export default function Nav2() {
  
  const btn = document.getElementById("menu-btn");
  const menu = document.getElementById("menu");

  function navToggle() {
    console.log(menu);
    btn.classList.toggle("open");
    menu.classList.toggle("flex");
    menu.classList.toggle("hidden");
  }
  
    return (
      <body>
           {/* <!-- Mobile Menu Start --> */}

      <div
        id="menu"
        class="z-40 absolute hidden top-0 bottom-0 left-0 flex-col self-end w-full min-screen py-1 pt-28 pl-12 space-y-3 text-lg text-white bg-[#00008b]"
      >
        <img src="./images/logomain.svg" class="mx-auto w-[150px]" alt="" />
        <a href="/feed" class="hover:text-ping-500">
          Home
        </a>
        <a href="/listings" class="hover:text-ping-500">
        Manage Properties
        </a>
     
      </div>

      {/* <!-- Mobile Menu Stop --> */}

      {/* <!-- Mobile header with menu button start --> */}

      <div class="z-40 flex flex-row items-center justify-between md:hidden bg-[#00008B] h-[60px] w-screen">
        <a href="/feed"><img src="/images/logomain.svg" class="w-[100px] ml-5" alt="fleeinn" /></a>
        <div class="md:hidden mr-5 mt-3">
          <button
            onClick={navToggle}
            id="menu-btn"
            type="button"
            class="z-40 block hamburger md:hidden focus outline-none"
          >
            <span class="hamburger-top"></span>
            <span class="hamburger-middle"></span>
            <span class="hamburger-bottom"></span>
          </button>
        </div>
      </div>

      {/* <!-- Mobile header with menu button end --> */}
      
        <div class="md:flex md:flext-col md:justify-start md:items-start">
          {/* <!--Sidebar start--> */}
          <div class="hidden md:justify-between md:flex md:flex-col md:text-center md:text-white md:w-64 md:rounded-l-none md:rounded-r-2xl md:bg-[#00008B] md:h-screen">
            <img
              src="/images/logomain.svg"
              class="p-2 mt-5 w-[200px] mx-auto"
              alt="freeinn"
            />
  
            <div class="flex flex-row mt-0 justify-center">
              <span class="mr-5">
                <img src="/images/homeicon.svg" />
              </span>
  
              <a
                href="/feed"
                class="font-bold hover:border-b-4 text-xl hover:duration-100"
              >
                Home
              </a>
            </div>
  
            <div class="flex flex-row mt-0 justify-center">
              <span class="mr-5">
                <img src="/images/homeicon.svg" />
              </span>
  
              <a
                href="/listings"
                class="font-bold hover:border-b-4 text-xl hover:duration-100"
              >
                Manage Properties
              </a>
            </div>
  
            <div class="flex flex-row mt-0 justify-center">
              <span class="mr-5">
                <img src="/images/homeicon.svg" />
              </span>
  
              <a
                href=""
                class="font-bold hover:border-b-4 text-xl hover:duration-100"
              >
                Home
              </a>
            </div>
  
            <div class="flex flex-row mt-0 justify-center">
              <span class="mr-5">
                <img src="/images/homeicon.svg" />
              </span>
  
              <a
                href=""
                class="font-bold hover:border-b-4 text-xl hover:duration-100"
              >
                Home
              </a>
            </div>
  
            {/* <!-- Footer menu start --> */}
  
            <div class="flex flex-col items-center w-64">
              <div class=" mb-5">
                <div class="flex flex-row mb-5">
                  <span class="mr-5">
                    <img src="/images/homeicon.svg" />
                  </span>
  
                  <a
                    href=""
                    class="font-bold hover:border-b-4 text-xl hover:duration-100"
                  >
                    Footer 2
                  </a>
                </div>
  
                <div class="flex flex-row mx-auto">
                  <span class="mr-5">
                    <img src="/images/homeicon.svg" />
                  </span>
  
                  <a
                    href=""
                    class="font-bold hover:border-b-4 text-xl hover:duration-100"
                  >
                    Footer 2
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    );
  }