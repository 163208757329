import './App.css';

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import MainRoutes from "./Routes";




function App() {
  return (
    
    <div className="App">
                     <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="dark"
        toastStyle={
          { backgroundColor: "#00008B" }
        }
      />
      <MainRoutes />
    </div>
  );
}

export default App;
