/*global google*/
import PropertyNav from "./nav";
import LoadingScreen from "react-loading-screen";
import loader from "../../../Resources/loader.gif";
import { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GoogleMap,
  useJsApiLoader,
  StandaloneSearchBox,
  Marker,
} from "@react-google-maps/api";
import { useToken } from "../../../auth/useToken";
import axios from "axios";
import { toast } from "react-toastify";

const containerStyle = {
  width: "100%",
  height: "400px",
};

export default function PropertyMap() {
  let navigate = useNavigate();

  let { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [token] = useToken();
  const [bounds, setBounds] = useState(null);
  const [searchBox, setSearchBox] = useState(null);
  const [place, setPlace] = useState({
    lat: 6.980195,
    lng: 79.981606,
  });

  const notifyerror = (msg) => toast.error(msg);
  const notifysuccess = (msg) => toast.success(msg);

  const loadlisting = async () => {
    setLoading(true);

    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/hotel/single/" + id,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    await axios(config)
      .then(function (response) {
        setLoading(false);
        const data = response.data;
        if (data === "") {
          notifyerror("Invalid listing!");
        } else {
          notifysuccess("Listing loaded!");

          let cordi = {
            lat: parseFloat(response.data.hotel.lat),
            lng: parseFloat(response.data.hotel.lng),
          };

          setPlace(cordi);
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
        notifyerror("Connection error!");
      });
  };

  useEffect(() => {
    setLoading(true);
    loadlisting();
  }, []);

  const updateCoordinates = () => {

    var tdata = {
      'lat' : place.lat,
      'lng' :   place.lng
    }

    var config = {
      method: "patch",
      url:
        process.env.REACT_APP_API_URL +
       "/hotel/updatecoordinates/" + id,
      headers: {
        Authorization: "Bearer " + token,
      },
      data :tdata
    };

    axios(config)
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        navigate("/addlisting/surroundings/" + id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBbvXASqJ6-dCmRyFcewrJkTK-ID5ff08w",
    libraries: ["places"],
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(place);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onSBLoad = (ref) => {
    setSearchBox(ref);
  };

  const onPlacesChanged = () => {
    let tmpplace = {
      lat: null,
      lng: null,
    };
    let results = searchBox.getPlaces();

    for (let i = 0; i < results.length; i++) {
      tmpplace.lat = results[i].geometry.location.lat();
      tmpplace.lng = results[i].geometry.location.lng();
    }
    setPlace(tmpplace);
  };

  const onMapClick = (e) => {
    let tmpplace = {
      lat: null,
      lng: null,
    };

    tmpplace.lat = e.latLng.lat();
    tmpplace.lng = e.latLng.lng();

    setPlace(tmpplace);
  };

  return isLoaded ? (
    <LoadingScreen loading={loading} bgColor="#ffffffcc" logoSrc={loader}>
      <body>
        <PropertyNav />

        <main>
          <div className="flex flex-col md:mt-[5%] md:ml-[25%] md:mr-[25%] mt-10 ml-7">
            <div>
              <h1 className="font-medium text-3xl text-left">
                Where's your place located?
              </h1>
            </div>
            <div></div>

            <div className="flex flex-col">
              <div className="mt-4 relative">
                <GoogleMap
                  options={{
                    disableDefaultUI: true,
                  }}
                  mapContainerStyle={containerStyle}
                  center={place}
                  zoom={10}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                  onClick={onMapClick}
                >
                  <div id="searchbox">
                    <StandaloneSearchBox
                      onLoad={(ref) => onSBLoad(ref)}
                      onPlacesChanged={onPlacesChanged}
                      bounds={bounds}
                    >
                      <input
                        type="text"
                        placeholder="Enter your property name"
                        className="rounded-2xl border-2 absolute  m-auto left-0 right-0  top-[10px] h-[40px] w-[320px] p-2"
                      />
                    </StandaloneSearchBox>
                  </div>

                  <Marker position={place} />

                  <></>
                </GoogleMap>
              </div>
            </div>
          </div>
        </main>

        <div className="z-40 fixed bottom-0">
          <div>
            <div className="mb-2 h-2 w-full bg-neutral-200 dark:bg-neutral-400">
              <div className="h-2 bg-[#00008B]" style={{ width: "40%" }}></div>
            </div>
          </div>

          <div className="flex flex-row items-center justify-between  h-[60px] w-screen">
            <button
              onClick={() => navigate("/addlisting/address/" + id)}
              type="button"
              className="font-bold ml-10 underline"
            >
              Back
            </button>
            <div className="mr-5">
              <button
                onClick={() => updateCoordinates()}
                type="button"
                className="text-center p-2 rounded-2xl mt-3 w-[100px] text-white font-bold text-sm bg-[#00008b] hover:bg-[#050544] duration-300"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </body>
    </LoadingScreen>
  ) : (
    <></>
  );
}
