import PropertyNav from "./nav";
import { useNavigate, useParams } from "react-router-dom";
import { useToken } from "../../../auth/useToken";
import { useState, useEffect } from "react";
import LoadingScreen from "react-loading-screen";
import loader from "../../../Resources/loader.gif";
import axios from "axios";
import { toast } from "react-toastify";

export default function PropertyRules() {

  let navigate = useNavigate();

  let { id } = useParams();

  const [token, setToken] = useToken();
  const [loading, setLoadig] = useState(false);
  const [rules, setRules]=useState();

  const notifyerror = (msg) => toast.error(msg);

  const loadlisting = () => {
    setLoadig(true);

    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/hotel/single/" + id,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        setLoadig(false);
        const data = response.data;
        if (data == "") {
          notifyerror("Invalid listing!");
        } else {
          setRules(response.data.rules);
        }
      })
      .catch(function (error) {
        setLoadig(false);
        notifyerror("Connection error!");
      });
  };

  useEffect(()=>{
    loadlisting();
  },[])

  
  const updateRules = () => {

    var tdata = {
      'id' : id,
      'field' : 'rules',
      'value' : rules
    }

    var config = {
      method: "patch",
      url: process.env.REACT_APP_API_URL + "/hotel/updatefield",
      headers: {
        Authorization: "Bearer " + token,
      },
      data :tdata
    };

    axios(config)
      .then((response) => {
        setLoadig(false);
        
        navigate("/addlisting/thankyou");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <LoadingScreen loading={loading} bgColor="#ffffffcc" logoSrc={loader}>
    <body>
      <PropertyNav />

      <main>
        <div className="flex flex-col md:mt-[5%] md:ml-[25%] md:mr-[25%] mt-10 ml-7">
          <div>
            <h1 className="font-medium text-3xl text-left">
              What are the rules you have on your property?
            </h1>
          </div>
          <div></div>

          <div className="flex flex-col">
        

            <div className="mt-10">
              <div className="flex flex-row">
                <div className="overflow-auto flex flex-col w-full mb-10 mr-5 ">
                  
                    <textarea
                     placeholder="Propery rules"
                      required
                      value={rules}
                      onChange={(e)=>setRules(e.target.value)}
                      className="h-[200px] resize-y rounded-lg w-full p-6 peer border-2 border-gray-300"
                    >
        
                    </textarea>
          
                </div>
   
              </div>   
           


            </div>
          </div>
        </div>
      </main>

      <div className="z-40 fixed bottom-0">
        <div>
          <div class="mb-2 h-2 w-full bg-neutral-200 dark:bg-neutral-400">
            <div class="h-2 bg-[#00008B]" style={{ width: "90%" }}></div>
          </div>
        </div>

        <div className="flex flex-row items-center justify-between  h-[60px] w-screen">
          <button onClick={() => navigate("/addlisting/contact/" + id)}
              type="button"className="font-bold ml-10 underline">Back</button>
          <div class="mr-5">
            <button
              onClick={() => updateRules()}
              type="button"
              className="text-center p-2 rounded-2xl mt-3 w-[100px] text-white font-bold text-sm bg-[#00008b] hover:bg-[#050544] duration-300"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </body>
    </LoadingScreen>
  );
}
