import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useToken } from "../../auth/useToken";
import { toast } from "react-toastify";
import axios from "axios";
import PropertyNav from "../listing/newproperty/nav";
import LoadingScreen from "react-loading-screen";
import loader from "../../Resources/loader.gif";
import { useForm } from "react-hook-form";

export default function AddRoom() {

  let navigate = useNavigate();

  const { id } = useParams();

  const [token, setToken] = useToken();
  const [loading, setLoading] = useState(false);
  const [rfacilities, setRfacilities] = useState();
  const [selected, setSelected] = useState([]);
  const [beds, setBeds] = useState(0);
  const [adults, setAdults] = useState(0);
  const [kids, setKids] = useState(0);
  const [roomtypes, setRoomtypes] = useState();
  const [roomviews, setRoomviews] = useState();

  useEffect(() => {
    setLoading(true);
    getRoomFacilities();
    getRoomTypes();
    getRoomviews();
  }, []);

  const notifyerror = (msg) => toast.error(msg);
  const notifysuccess = (msg) => toast.success(msg);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setLoading(true);
    addroom(data);
  };

  const addroom = (data) => {
  
    let pdate = JSON.stringify({
      hotelid: id,
      name: data.name,
      rtypeid: data.rtypeid,
      rviewid: data.rviewid,
      smoking: data.smoking,
      bathroom: data.bathroom,
      bedtype: data.bedtype,
      numbeds: beds,
      adults: adults,
      kids: kids,
      facilities: JSON.stringify(selected),
    });

    var config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/room",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: pdate,
    };

    axios(config)
      .then((response) => {
        notifysuccess("Room added!");
        setLoading(false);
        navigate("/rooms/manage/" + id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSelect = (id) => {
    let tmparry = selected;

    if (tmparry.indexOf(id) === -1) {
      setSelected([...tmparry, id]);
    } else {
      const tarr = [...tmparry];
      tarr.splice(tmparry.indexOf(id), 1);

      setSelected(tarr);
    }
  };

  const getRoomFacilities = () => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/RoomFacility",
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        const data = response.data;
        if (data === "") {
          notifyerror("Feed error!");
        } else {
          setRfacilities(response.data);
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  const getRoomTypes = () => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/RoomType",
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        const data = response.data;
        if (data === "") {
          notifyerror("Data error!");
        } else {
          setRoomtypes(response.data);
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  const getRoomviews = () => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/RoomView",
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        const data = response.data;
        if (data === "") {
          notifyerror("Data error!");
        } else {
          setRoomviews(response.data);
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  let roomfacilities = rfacilities ? (
    rfacilities.map((data, i) => {
      if (selected.indexOf(data.id) !== -1) {
        return (
          <div
            id={data.id}
            onClick={() => handleSelect(data.id)}
            className="rounded-xl border-2 py-4 px-4 border-3 border-black transition duration-150"
          >
            {/* <img src={`/images/amenities/${data.icon}`} className="mb-2 h-10" /> */}
            <img
              src="/images/amenities/Carbon monoxide alarm.svg"
              className="mb-2 h-10"
            />
            <h2 className="font-semibold text-left">{data.facility}</h2>
          </div>
        );
      } else {
        return (
          <div
            id={data.id}
            onClick={() => handleSelect(data.id)}
            className="rounded-xl border-2 py-4 px-4 hover:border-3 hover:border-black hover:transition duration-150"
          >
            {/* <img src={`/images/amenities/${data.icon}`} className="mb-2 h-10" /> */}
            <img
              src="/images/amenities/Carbon monoxide alarm.svg"
              className="mb-2 h-10"
            />
            <h2 className="font-semibold text-left">{data.facility}</h2>
          </div>
        );
      }
    })
  ) : (
    <span>No Data</span>
  );

  let rtypes = roomtypes ? (
    roomtypes.map((data, i) => {
      return <option value={data.id}>{data.type}</option>;
    })
  ) : (
    <span>No Data</span>
  );

  let rviews = roomviews ? (
    roomviews.map((data, i) => {
      return <option value={data.id}>{data.view}</option>;
    })
  ) : (
    <span>No Data</span>
  );

  return (
    <LoadingScreen loading={loading} bgColor="#ffffffcc" logoSrc={loader}>
      <body>
        <PropertyNav />

        {/* Room add form */}

        <section>
          <div class="flex items-center m-5">
            <div class="px-3 text-gray-800 font-bold text-2xl">
              Basic information about the room
            </div>
            <div class="border-t border-4 border-[#00008B] flex-grow"></div>

            <div class="border-t border-4 border-[#00008B] flex-grow"></div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-row">
              <div className="flex w-full flex-col">
                <div className="m-5 text-left">
                  <label className="">Property</label>
                  <input
                    className="mt-2 p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    type="text"
                    disabled
                    value="Property name"
                  />
                </div>
              </div>

              <div className="flex w-full flex-col">
                <div className="m-5 text-left">
                  <label className="">Room Name</label>
                  <input
                    {...register("name", { required: true })}
                    className="mt-2 p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    type="text"
                    placeholder="Room name"
                  />
                  {errors.name && (
                    <span className="text-red-700 font-bold">
                      This field is required *{" "}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="flex  flex-row">
              <div className="flex w-full flex-col">
                <div className="m-5 text-left">
                  <label className="">Room type</label>
                  <select
                    {...register("rtypeid", { required: true })}
                    className="mt-2 p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option>Select Room Type</option>
                    {rtypes ? rtypes : <span>No results</span>}
                  </select>
                  {errors.rtype && (
                    <span className="text-red-700 font-bold">
                      This field is required *{" "}
                    </span>
                  )}
                </div>
              </div>

              <div className="flex w-full flex-col">
                <div className="m-5 text-left">
                  View
                  <select
                    {...register("rviewid", { required: true })}
                    className="mt-2 p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option>Select a the view</option>
                    {rviews ? rviews : <span>No results</span>}
                  </select>
                  {errors.view && (
                    <span className="text-red-700 font-bold">
                      This field is required *{" "}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-row">
              <div className="flex w-full flex-col">
                <div className="m-5 text-left">
                  <label className="">Smoking policy</label>
                  <select
                    {...register("smoking", { required: true })}
                    className="mt-2 p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option>Please select</option>
                    <option value="0">Non smoking</option>
                    <option value="1">Smoking</option>
                  </select>
                  {errors.smoking && (
                    <span className="text-red-700 font-bold">
                      This field is required *{" "}
                    </span>
                  )}
                </div>
              </div>

              <div className="flex w-full flex-col">
                <div className="m-5 text-left">
                  <label className="">Bathroom</label>
                  <select
                    {...register("bathroom", { required: true })}
                    className="mt-2 p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option>Please select</option>
                    <option value="Private">Private</option>
                    <option value="Ensuite">Ensuite</option>
                    <option value="Common">Common</option>
                  </select>
                  {errors.baths && (
                    <span className="text-red-700 font-bold">
                      This field is required *{" "}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="flex  flex-row">
              <div className="flex w-full flex-col">
                <div className="m-5 text-left">
                  <label className="">Bed Types</label>
                  <select
                    {...register("bedtype", { required: true })}
                    className="mt-2 p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option>Please select</option>
                    <option value="King">King</option>
                    <option value="Queen">Queen</option>
                    <option value="Double">Double</option>
                    <option value="Single">Single</option>
                  </select>
                </div>
              </div>

              <div className="flex w-full flex-col">
                <div className="m-5 text-left">
                  Number of beds
                  <div>
                    <div className="custom-number-input h-10 w-full">
                      <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                        <button
                          type="button"
                          onClick={() => setBeds(beds - 1)}
                          data-action="decrement"
                          className="bg-[#00008B] text-white hover:text-white hover:bg-[#181852fd] h-full w-20 rounded-l cursor-pointer outline-none"
                        >
                          <span class="m-auto text-2xl font-thin">−</span>
                        </button>
                        <input
                          type="number"
                          className="outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                          name="custom-input-number"
                          value={beds}
                        />
                        <button
                          type="button"
                          onClick={() => setBeds(beds + 1)}
                          data-action="increment"
                          className="bg-[#00008B] text-white hover:text-white hover:bg-[#181852fd] h-full w-20 rounded-r cursor-pointer"
                        >
                          <span className="m-auto text-2xl font-thin">+</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex  flex-row">
              <div className="flex w-full flex-col">
                <div className="m-5 text-left">
                  Maximum number of adults allowed
                  <div>
                    <div className="custom-number-input h-10 w-full">
                      <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                        <button
                          type="button"
                          onClick={() => setAdults(adults - 1)}
                          data-action="decrement"
                          className="bg-[#00008B] text-white hover:text-white hover:bg-[#181852fd] h-full w-20 rounded-l cursor-pointer outline-none"
                        >
                          <span class="m-auto text-2xl font-thin">−</span>
                        </button>
                        <input
                          type="number"
                          className="outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                          name="custom-input-number"
                          value={adults}
                        />
                        <button
                          type="button"
                          onClick={() => setAdults(adults + 1)}
                          data-action="increment"
                          className="bg-[#00008B] text-white hover:text-white hover:bg-[#181852fd] h-full w-20 rounded-r cursor-pointer"
                        >
                          <span className="m-auto text-2xl font-thin">+</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col">
                <div className="m-5 text-left">
                  Maximum number of kids allowed
                  <div>
                    <div className="custom-number-input h-10 w-full">
                      <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                        <button
                          type="button"
                          onClick={() => setKids(kids - 1)}
                          data-action="decrement"
                          className="bg-[#00008B] text-white hover:text-white hover:bg-[#181852fd] h-full w-20 rounded-l cursor-pointer outline-none"
                        >
                          <span class="m-auto text-2xl font-thin">−</span>
                        </button>
                        <input
                          type="number"
                          className="outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                          name="custom-input-number"
                          value={kids}
                        />
                        <button
                          type="button"
                          onClick={() => setKids(kids + 1)}
                          data-action="increment"
                          className="bg-[#00008B] text-white hover:text-white hover:bg-[#181852fd] h-full w-20 rounded-r cursor-pointer"
                        >
                          <span className="m-auto text-2xl font-thin">+</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex items-center m-5">
              <div class="px-3 text-gray-800 font-bold text-2xl">
                Room Facilities
              </div>
              <div class="border-t border-4 border-[#00008B] flex-grow"></div>

              <div class="border-t border-4 border-[#00008B] flex-grow"></div>
            </div>

            <div class="grid grid-cols-3 gap-2 m-5 content-start">
              {roomfacilities ? roomfacilities : <span>Error!</span>}
            </div>

            <div class="flex flex-row items-center m-5">
              <button class="flex flex-col items-center w-full bg-red-600 rounded px-3 py-1 font-semibold text-white mr-2 mb-2 hover:bg-red-400 hover:text-black">
                Cancel
              </button>

              <button type="submit" class="flex flex-col items-center w-full rounded px-3 py-1 font-semibold text-white mr-2 mb-2 bg-[#00008B] hover:bg-blue-900 hover:text-white">
                Add Room
              </button>
            </div>
          </form>
        </section>
      </body>
    </LoadingScreen>
  );
}
