import {Navigate, Outlet} from 'react-router-dom';
import { useUser } from './useUser';

export const PrivateRoute= props => {

    const user=useUser();


    // if(!user) return <Navigate to="/" />

    // return <Outlet {...props}/>
    return user ? <Outlet /> : <Navigate to="/" replace />;

}