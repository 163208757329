import Nav from "../shared/nav";
import LoadingScreen from "react-loading-screen";
import loader from "../../Resources/loader.gif";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useToken } from "../../auth/useToken";

export default function MyListings() {
 

  const [listings, setListing] = useState();

  const [loading, setLoading] = useState(false);

  const [token] = useToken();

  const notifysuccess = (msg) => toast.success(msg);
  const notifyerror = (msg) => toast.error(msg);

  const getlistings = () => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/hotel/approveduserhotels",
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        const data = response.data.hotels;
        if (data === "") {
          notifyerror("Invalid listings!");
        } else {
          notifysuccess("Listings loaded!");
          setListing(data);
        }
      })
      .catch(function (error) {
        setLoading(false);
        notifyerror("Connection error!");
      });
  };

  useEffect(() => {
    setLoading(true);

    getlistings();
  }, []);

  let properties = listings ? (
    listings.map((data, i) => {
      return (
        <a
          href={"/managelisting/" + data.id + "/" + data.name}
          className="relative group overflow-hidden rounded-lg shadow-lg hover:shadow-xl transition-transform duration-300 ease-in-out hover:-translate-y-2"
        >
          <a href={"/managelisting/" + data.id + "/" + data.name} className="absolute inset-0 z-10">
            <span className="sr-only">View</span>
          </a>
          <img
            src="/images/imgplaceholder.svg"
            alt=""
            width="500"
            height="400"
            className="object-cover w-full h-64"
          />
          <div className="bg-[#00008b] text-white p-4 ">
            <h3 className="font-bold text-xl">{data.name}</h3>
          </div>
        </a>
      );
    })
  ) : (
    <span>No Data</span>
  );

  return (
    <LoadingScreen loading={loading} bgColor="#ffffffcc" logoSrc={loader}>
      <div className="flex md:flex-row flex-col bg-[#F8F8F8]">
        <Nav />

        <div className="md:flex-1 flex-col md:ml-[275px] md:mr-1 ml-6 mr-6">
          <div className="flex flex-col h-screen">
            <main className="flex-1 overflow-y-auto p-4">
              <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                {properties ? properties : <span>No Data</span>}
              </div>
            </main>
          </div>
        </div>
      </div>
    </LoadingScreen>
  );
}
