import {useState, useEffect } from "react";
import LoadingScreen from "react-loading-screen";
import loader from "../../Resources/loader.gif";
import axios from "axios";
import { toast } from "react-toastify";
import { useToken } from "../../auth/useToken";
import PropertyNav from "./newproperty/nav";


export default function Listings() {

 

  const [loading, setLoading] = useState(false);
  const [listings, setListing] = useState();

  const [token] = useToken();

  const notifysuccess = (msg) => toast.success(msg);
  const notifyerror = (msg) => toast.error(msg);


  const getlistings = () => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/hotel/userhotels",
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        const data = response.data.hotels;
        if (data === "") {
          notifyerror("Invalid listings!");
        } else {
          notifysuccess("Listings loaded!");
          setListing(data);
        }
      })
      .catch(function (error) {
        setLoading(false);
        notifyerror("Connection error!");
      });
  };

  useEffect(() => {
    setLoading(true);
    
    getlistings();

  }, []);


  let properties = listings ? (
    listings.map((data, i) => {
      return (
        <a
        href={`/addlisting/updatepropertyname/${data.id}`}
        class="block border-2 rounded-lg bg-white p-6 
    shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]
    hover:bg-gray-100 hover:duration-200 text-left
    "
      >
        <span class="text-xl font-medium ">
          {data.name} started {data.created_at}
        </span>
      </a>
      );
    })
  ) : (
    <span>No Data</span>
  );

  return (
    <LoadingScreen loading={loading} bgColor="#ffffffcc" logoSrc={loader}>
  <body>
  <PropertyNav/>

      <div className="flex flex-col md:mt-[8%] md:ml-[25%] md:mr-[25%] mt-10 ml-7">
        <div>
          <h1 className="font-medium text-3xl text-left">Welcome to FleeInn Listings</h1>
        </div>
        <div>

       
        </div>
       
       {listings ? 
        <div className="flex-col mr-2 mt-3">
        <h2 className="mb-3 font-medium text-2xl text-left mt-8">
            Finish your listing
          </h2>
        {properties ? properties : <span></span>}
        </div>
       :
       <div></div>
    } 

       

        <div className="flex-col mr-2 mt-5">
          <div>
            <h2 className="font-medium text-2xl text-left mt-8">
              Start a new listing
            </h2>
          </div>

          <a
            href="/addlisting/propertyname"
            class="flex justify-between border-b-2 bg-white p-6 
       
        hover:bg-gray-100 hover:duration-200
        "
          >
            <h5 class="text-xl font-medium text-left">Create a new listing</h5>
            <img className="w-7" src="/images/icons/right-arrow.png" />
          </a>
        </div>
      </div>
    </body>
    </LoadingScreen>
  );
}
