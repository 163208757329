import PropertyNav from "./nav";
import LoadingScreen from "react-loading-screen";
import loader from "../../../Resources/loader.gif";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useToken } from "../../../auth/useToken";
import axios from "axios";
import { toast } from "react-toastify";

export default function PropertyAddress() {

  let navigate = useNavigate();

  let { id } = useParams(); 

  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();
  const [cities, setCities] = useState();
  const [districts, setDistricts]=useState();
  const [addrinfo,setAddrinfo]=useState({});

  const notifyerror = (msg) => toast.error(msg);
  const notifysuccess = (msg) => toast.success(msg);

  const loadlisting=()=>{
    setLoading(true);

    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/hotel/single/" + id,
      headers: { 
        'Authorization': 'Bearer '+token
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        const data = response.data;
        if (data == "") {
          notifyerror("Invalid listing!");
       
        } else {
          notifysuccess("Listing loaded!");
          let data={
            'country' : response.data.hotel.country,
            'address1' :  response.data.hotel.address1,
            'address2' :  response.data.hotel.address2,
            'address3' :  response.data.hotel.address3,
            'postalcode' :  response.data.hotel.postalcode,
            'city_id' :  response.data.hotel.city_id,
          }
          setAddrinfo(data);
    
        }
      })
      .catch(function (error) {
        setLoading(false);
        notifyerror("Connection error!");
        
      });
  }

  const alldistricts = () => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/district/all",
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        const data = response.data.districts;
        if (data == "") {
          notifyerror("Feed error!");
        } else {
          setDistricts(data);
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  const getCities = (id) => {
    
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/city/" + id,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        const data = response.data.cities;
        if (data == "") {
          notifyerror("Feed error!");
        } else {
          setCities(data);
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(()=>{
    setLoading(true);
    loadlisting();
    alldistricts();

  },[])

  const updateAddress=()=>{

    var config = {
      method: 'patch',
      url: process.env.REACT_APP_API_URL + "/hotel/updateaddress/" + id,
      headers: { 
        'Authorization': 'Bearer '+token,
      },
      data :addrinfo
    
    };
    
    axios(config)
    .then((response)=> {
      setLoading(false);
      navigate('/addlisting/map/'+id)
    })
    .catch(function (error) {
      console.log(error);
    });


  }


  let districtsop = districts ? (
    districts.map((data, i) => {
      return <option value={data.id}>{data.name}</option>;
    })
  ) : (
    <span>No Data</span>
  );

  let citiesop = cities ? (
    cities.map((data, i) => {
      return <option value={data.id}>{data.name}</option>;
    })
  ) : (
    <span>No Data</span>
  );

  return (
    <LoadingScreen loading={loading} bgColor="#ffffffcc" logoSrc={loader}>
    <body>
      <PropertyNav />

      <main>
        <div className="flex flex-col md:mt-[5%] md:ml-[25%] md:mr-[25%] mt-10 ml-7">
          <div>
            <h1 className="font-medium text-3xl text-left">
              Confirm your address
            </h1>
          </div>
          <div></div>

          <div className="flex flex-col">
            <div className="mt-5">
              <select
                     onChange={(e)=>{
                      setAddrinfo(prevState => ({
                        ...prevState,
                          country: e.target.value
                      }))
                   
                    }}
              className="p-4 bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option>Choose a country</option>
                <option selected>Sri Lanka</option>
              </select>
            </div>

            <div className="mt-4">
              <div className="flex flex-row">
                <div className="flex flex-col w-full">
                  <div className="mr-2 relative group ">
                    <input
                      type="text"
                      id="address"
                      value={addrinfo.address1}
                      onChange={(e)=>{
                        setAddrinfo(prevState => ({
                          ...prevState,
                            address1: e.target.value
                        }))
                     
                      }}
                      required
                      className="rounded-lg w-full h-10 p-6 peer border-2 border-gray-300"
                    />
                    <label
                      for="address"
                      className="transform transition-all absolute top-0 left-0 h-full flex items-center pl-2 text-medium group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0"
                    >
                      Address Line 1
                    </label>
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <div className=" relative group ">
                    <input
                      type="text"
                      id="address"
                      required
                      value={addrinfo.address2}
                      onChange={(e)=>{
                        setAddrinfo(prevState => ({
                          ...prevState,
                            address2: e.target.value
                        }))
                     
                      }}
                      className="rounded-lg w-full h-10 p-6 peer border-2 border-gray-300"
                    />
                    <label
                      for="address"
                      className="transform transition-all absolute top-0 left-0 h-full flex items-center pl-2 text-medium group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0"
                    >
                      Address Line 2
                    </label>
                  </div>
                </div>
              </div>   
              
              <div className="mt-6 flex flex-row">
                <div className="flex flex-col w-full">
                  <div className="mr-2 relative group ">
                    <input
                      type="text"
                      id="address"
                      value={addrinfo.address3}
                      onChange={(e)=>{
                        setAddrinfo(prevState => ({
                          ...prevState,
                            address3: e.target.value
                        }))
                     
                      }}
                      className="rounded-lg w-full h-10 p-6 peer border-2 border-gray-300"
                    />
                    <label
                      for="address"
                      className="transform transition-all absolute top-0 left-0 h-full flex items-center pl-2 text-medium group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0"
                    >
                      Address Line 3
                    </label>
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <div className=" relative group ">
                    <input
                      type="text"
                      id="postalcode"
                      required
                      value={addrinfo.postalcode}
                      onChange={(e)=>{
                        setAddrinfo(prevState => ({
                          ...prevState,
                          postalcode: e.target.value
                        }))
                     
                      }}
                      className="rounded-lg w-full h-10 p-6 peer border-2 border-gray-300"
                    />
                    <label
                      for="postalcode"
                      className="transform transition-all absolute top-0 left-0 h-full flex items-center pl-2 text-medium group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0"
                    >
                      Postal Code
                    </label>
                  </div>
                </div>
              </div>

              <div className="mt-2">
                <select onChange={(e)=>getCities(e.target.value)} className="p-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  <option>Choose your District</option>
                  {districtsop ? districtsop : <span>No Data</span>}
                </select>
              </div>

              <div className="mt-2">
                <select
                  onChange={(e)=>{
                    setAddrinfo(prevState => ({
                      ...prevState,
                        city_id: e.target.value
                    }))
                 
                  }}
                className="p-4 bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  <option>Choose your City</option>
                  {citiesop ? citiesop : <span>No Data</span>}
                </select>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div className="z-40 fixed bottom-0">
        <div>
          <div className="mb-2 h-2 w-full bg-neutral-200 dark:bg-neutral-400">
            <div className="h-2 bg-[#00008B]" style={{ width: "30%" }}></div>
          </div>
        </div>

        <div className="flex flex-row items-center justify-between  h-[60px] w-screen">
          <button onClick={() => navigate("/addlisting/propertytype/" + id)}  type="button" className="font-bold ml-10 underline">Back</button>
          <div className="mr-5">
            <button
              onClick={() =>updateAddress()}
              type="button"
              className="text-center p-2 rounded-2xl mt-3 w-[100px] text-white font-bold text-sm bg-[#00008b] hover:bg-[#050544] duration-300"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </body>
    </LoadingScreen>
  );
}
